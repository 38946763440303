import { useMutation } from 'react-query'
import { apiClient } from 'src/api/api-client'
import { ANALYTICS_EVENTS, useAnalytics } from 'src/hooks/useAnalytics'
import { queryClient } from 'src/queries/reactQueryClient'
import { QUERY_KEY_CURRENT_ACCOUNT } from './queryKeys'

export const useAuth = () => {
  const client = apiClient.auth()
  const analytics = useAnalytics()

  const requestCodeWithEmailMutation = useMutation(client.sendEmail)
  const verifyCodeMutation = useMutation(client.verifyCode, {
    onSuccess: ({ token }) => {
      if (token) {
        localStorage.setItem('token', token)
        analytics.fireEvent(ANALYTICS_EVENTS.Login)
        queryClient.invalidateQueries({ queryKey: QUERY_KEY_CURRENT_ACCOUNT })
      }
    },
  })
  const logoutMutation = useMutation(client.logout, {
    onSuccess: () => {
      localStorage.removeItem('token')
      queryClient.clear()
      analytics.fireEvent(ANALYTICS_EVENTS.Logout)
    },
  })

  return {
    requestCodeWithEmail: { ...requestCodeWithEmailMutation },
    verifyCode: { ...verifyCodeMutation },
    logout: { ...logoutMutation },
  }
}
